<template>
	<v-toolbar-items v-if="show" cols="6" md="6" id="menu-nav">
		<div id="menu-nav-desktop" v-if="currentRoute.name != 'Login'" class="d-flex align-center justify-center">
			<a href="/" class="pl-3 pr-3 navBarLink">
				<span>Espace Personnel</span>
			</a>
			<router-link
				:to="'/dashboard/rules/' + formationId"
				class="pl-3 pr-3 navBarLink"
				:class="{ purpleColor: currentRoute.name == 'Rules' }"
			>
				<span @click="onDashboardRules()">Espace règles</span>
			</router-link>
			<router-link
				:to="{ name: 'Assistant', params: { formationId: formationId } }"
				class="pl-3 pr-8 navBarLink"
				:class="{ purpleColor: currentRoute.name == 'Assistant' }"
			>
				<span @click="onDashboardAssistant()">Assistant</span>
			</router-link>
		</div>
	</v-toolbar-items>
</template>

<script>
import { showNavBar } from '@/utils/navbar.js';
import MixPanel from '@/utils/mixpanel.js';

export default {
	name: 'OrthographNavItemsDesktop',
	props: {
		currentRoute: Object,
		formationId: String,
	},
	computed: {
		show() {
			return showNavBar(this.currentRoute.name);
		},
	},
	methods: {
		onDashboardRules() {
			MixPanel.trackToRule;
		},
		onDashboardAssistant() {
			MixPanel.trackToAssistant;
		},
	},
};
</script>

<style scoped lang="scss">
#menu-nav-desktop {
	a {
		&:not(:last-child) {
			border-right: 1px solid #989fa9;
		}
	}

	a {
		color: #989fa9 !important;
	}

	a:hover {
		color: #686bdf !important;
	}

	.navBarLink {
		font-weight: 500;
		letter-spacing: 0.0892857143em;
		text-transform: uppercase;
		font-size: 12px;
	}

	.purpleColor {
		color: #686bdf !important;
	}
}
</style>
