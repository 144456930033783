import axios from 'axios';

const state = () => ({
	questionsRules: [],
});

const getters = {
	questionsRules: (state) => {
		return state.questionsRules;
	},
};
const mutations = {
	questionsRules(state, infos) {
		state.questionsRules = infos;
	},
};
const actions = {
	async getTrainingQuestions(store, { idRules }) {
		const res = await axios.get('/api/rules/questions/' + idRules);
		store.commit('questionsRules', res.data.questionsRules);
	},
	async postTrainingQuestions(store, { score, idFormation, idRules, answers }) {
		await axios.post('/api/rules/questions/result', {
			score,
			idFormation,
			idRules,
			answers,
		});
	},

	async getRulesOfParcours(store, { parcoursId }) {
		const res = await axios.get('/api/rules/parcours/' + parcoursId);
		return res.data.data;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
