import axios from 'axios';

const state = () => ({});

const getters = {};
const mutations = {};

const actions = {
	async getTrackDetail(store, { trackId }) {
		const res = await axios.get('/api/tracks/' + trackId + '?withResource=true');
		return res.data.data.track;
	},
	async createTrackSubmitResponse(store, response) {
		const res = await axios.post('/api/tracks/submit-response', {
			...response,
		});
		return res;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
