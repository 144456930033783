import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/pages/Login.vue'),
		meta: { header: 'Connexion' },
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next({ name: 'Dashboard' });
			else next();
		},
	},
	{
		path: '/forget-password',
		name: 'ForgetPassword',
		component: () => import('../views/pages/ForgetPassword.vue'),
		meta: { header: 'Connexion' },
	},
	{
		path: '/login-sso',
		name: 'LoginSSO',
		component: () => import('../views/pages/LoginSSO.vue'),
		meta: { header: 'Connexion' },
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next({ name: 'Dashboard' });
			else next();
		},
	},
	{
		path: '/login-sso-process',
		name: 'LoginSSOProcess',
		component: () => import('../views/pages/LoginSSOProcess.vue'),
		meta: { header: 'Connexion' },
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next({ name: 'Dashboard' });
			else next();
		},
	},
	{
		path: '/assistant/:formationId',
		name: 'Assistant',
		component: () => import('../views/pages/Assistant.vue'),
		meta: { header: 'Assistant' },
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next();
			else next({ name: 'Login' });
		},
	},
	{
		path: '/dashboard/profile/redaction/:formationId',
		name: 'Redaction Dashboard',
		component: () => import('../views/pages/RedactionDashboard.vue'),
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next();
			else next({ name: 'Login' });
		},
		children: [
			{
				path: 'index',
				name: 'Redaction Dashboard Index',
				component: () => import('../views/pages/RedactionDashBoardIndex.vue'),
			},
			{
				path: ':parcoursId',
				name: 'Redaction Dashboard Parcours Detail',
				component: () => import('../views/pages/RedactionParcoursDetail.vue'),
			},
			{
				path: ':idParcours/:idCategory',
				name: 'Redaction Dashboard Chapter Detail',
				component: () => import('../views/pages/RedactionChapterDetail.vue'),
			},
		],
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
		children: [
			{
				path: 'profile',
				name: 'Profile',
				component: () => import(/* webpackChunkName: "rules" */ '../views/Profile.vue'),
				meta: { header: 'Espace Personnel' },
				children: [
					{
						path: '',
						name: 'Profile Index',
						component: () => import('../components/dashboard/profile/profileIndex/ProfileIndex.vue'),
					},
					{
						path: 'leaderboard/weekly',
						name: 'Leaderboard Weekly',
						component: () => import('../views/leaderboard/WeeklyLeaderBoardPage.vue'),
					},
					{
						path: ':idParcours',
						name: 'Parcours Detail',
						component: () => import('../components/dashboard/profile/parcours/ParcoursDetail.vue'),
					},
					{
						path: ':idParcours/:idCategory',
						name: 'Category Detail',
						component: () => import('../components/dashboard/profile/chapter/ChapterDetail.vue'),
					},
				],
			},
			{
				path: 'rules/:idFormation',
				name: 'Rules',
				component: () => import(/* webpackChunkName: "rules" */ '../views/pages/Rules.vue'),
				meta: { header: 'Espace Règles' },
			},
			{
				path: 'parametres',
				name: 'Parametres',
				component: () => import('../views/Parametres.vue'),
				meta: { header: 'Paramètres' },
			},
		],
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next();
			else next({ name: 'Login' });
		},
	},
	{
		path: '/reset-password/:idUser',
		name: 'ResetPassword',
		component: () => import('../views/pages/ResetPassword.vue'),
	},
	{
		path: '/onboarding/:idUser',
		name: 'Onboarding',
		component: () => import(/* webpackChunkName: "onboarding" */ '../views/pages/Onboarding.vue'),
	},
	{
		path: '/test-positionnement/:idUser/:idFormation',
		name: 'TestPositionnement',
		component: () => import(/* webpackChunkName: "test-positionnement" */ '../views/pages/TestPositionnement.vue'),
		beforeEnter: (to, from, next) => {
			if (from.name == 'Onboarding') to.meta.from = 'Onboarding';
			next();
		},
	},
	{
		path: '/exercices/:idFormation/:idExercice',
		name: 'Exercice',
		component: () => import(/* webpackChunkName: "exercice" */ '../views/pages/Exercice.vue'),
	},
	{
		path: '/story/:idFormation/:idExercice/:idStory',
		name: 'Story',
		component: () => import(/* webpackChunkName: "story" */ '../views/pages/Story.vue'),
	},
	{
		path: '/rules-training/:idFormation/:idRules',
		name: 'Rules Training',
		component: () => import(/* webpackChunkName: "story" */ '../views/pages/RulesTraining.vue'),
	},
	{
		path: '/report',
		name: 'Report',
		redirect: { name: 'PageNotFound' },
		component: () => import(/* webpackChunkName: "report" */ '../views/reports/Index.vue'),
		children: [
			{
				path: 'category/:idFormation/:idCategory',
				name: 'Report Category',
				component: () => import(/* webpackChunkName: "report-category" */ '../views/reports/Category.vue'),
			},
			{
				path: 'parcours/:idFormation/:idParcours',
				name: 'Report Parcours',
				component: () => import('../views/reports/ReportParcours.vue'),
			},
			{
				path: 'end-formation/:idFormation/:idParcours',
				name: 'Report Formation',
				component: () => import('../views/reports/ReportFormation.vue'),
			},
		],
	},
	{
		path: '/report/redaction',
		name: 'Report Redaction',
		redirect: { name: 'PageNotFound' },
		component: () => import('../views/reports/Index.vue'),
		children: [
			{
				path: 'category/:formationId/:categoryId',
				name: 'Redaction Chapter Report',
				component: () => import('../views/reports/RedactionChapterReport.vue'),
			},
			{
				path: 'parcours/:idFormation/:idParcours',
				name: 'Redaction Parcours Report',
				component: () => import('../views/reports/RedactionParcoursReport.vue'),
			},
			{
				path: 'end-formation/:idFormation/:idParcours',
				name: 'Redaction Formation Report',
				component: () => import('../views/reports/RedactionFormationReport.vue'),
			},
		],
	},
	{
		path: '/',
		name: 'Home',
		beforeEnter: (to, from, next) => {
			next({ name: 'Dashboard' });
		},
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../views/pages/Register.vue'),
		meta: { header: 'Inscription' },
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next({ name: 'Dashboard' });
			else next();
		},
	},
	{
		path: '/logout',
		name: 'Logout',
		component: () => import('../views/pages/Logout.vue'),
	},
	{
		path: '/unlock-parcours/:idFormation',
		name: 'ChooseParcousToUnlock',
		component: () => import('../views/ChooseParcousToUnlock.vue'),
	},
	{
		path: '/sessions/:formationId/:sessionId',
		name: 'SessionDetailPage',
		component: () => import('../views/pages/SessionDetail.vue'),
	},
	{
		path: '/sessions/:formationId/:sessionId/result',
		name: 'SessionResultPage',
		component: () => import('../views/pages/SessionResult.vue'),
	},
	{
		path: '/tracks/:formationId/:sessionId/:sessionBlockId/:trackId',
		name: 'TrackPage',
		component: () => import('../views/pages/TrackPage.vue'),
	},
	{
		path: '*',
		name: 'PageNotFound',
		component: () => import('../views/pages/PageNotFound.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
