<template>
	<v-row class="mb-6" justify="center" no-gutters>
		<div class="d-flex flex-column message-error-container">
			<h1>Oops! Notre application n'est pas compatible avec votre navigateur</h1>
			<div>
				<div class="mt20">
					<h3 class="mb20">Pour y avoir accès, voici les navigateurs supportés:</h3>
					<v-btn class="ml15 mb10 hvr-shrink" href="https://www.google.com/intl/fr/chrome/" target="_blank">
						<span>Google Chrome</span>
						<img class="ml5 browserlogo" src="@/assets/icons/browser/googlechrome.png" />
					</v-btn>
					<v-btn class="ml15 mb10 hvr-shrink" href="https://support.apple.com/en-us/HT204416" target="_blank">
						<span>Safari</span>
						<img class="ml5 browserlogo" src="@/assets/icons/browser/safari.png" />
					</v-btn>
					<v-btn class="ml15 mb10 hvr-shrink" href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">
						<span>Firefox</span>
						<img class="ml5 browserlogo" src="@/assets/icons/browser/firefox.png" />
					</v-btn>
					<v-btn class="ml15 mb10 hvr-shrink" href="https://www.microsoft.com/en-us/edge" target="_blank">
						<span>Microsoft Edge</span>
						<img class="ml5 browserlogo" src="@/assets/icons/browser/edge.png" />
					</v-btn>
				</div>
			</div>
			<div class="image-error-container">
				<img src="@/assets/images/error.jpg" class="image-error" alt="" />
			</div>
		</div>
	</v-row>
</template>

<script>
export default {
	name: 'Error',
};
</script>

<style scoped>
.browserlogo {
	width: 20px;
}
.activeRules {
	text-decoration: initial;
	background-image: linear-gradient(#6afdef);
	border-radius: 5px;
}
.activeRules:hover {
	text-decoration: underline;
}
.image-error {
	max-width: 50%;
}
.image-error-container {
	text-align: center;
	margin-top: 50px;
}
.message-error-container {
	margin-top: 50px;
	text-align: center;
	padding-left: 30px;
	padding-right: 30px;
}
.more-error {
	margin-top: 20px;
}
@media only screen and (max-width: 600px) {
	.image-error {
		max-width: 80%;
	}
	.more-error {
		padding-left: 30px;
		padding-right: 30px;
	}
}
</style>
