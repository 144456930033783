import { CategoryStatus } from '../constants/category';
import { SessionStatus } from '../constants/session';

const decideSessionState = (session, sessionScore, lastSessionId) => {
	if (session._id === lastSessionId && sessionScore == null) return SessionStatus.OPEN;
	if (session._id === lastSessionId && sessionScore != null) return SessionStatus.ONGOING;
	if (sessionScore && sessionScore.score > 80) return SessionStatus.PASSED;
	if (sessionScore && sessionScore.passed_date) return SessionStatus.PASSED;
	return SessionStatus.LOCKED;
};

const decideCategoryStatus = (category, totalPassedSessions, lastCategoryId) => {
	if (category.sessions.length === totalPassedSessions) return CategoryStatus.COMPLETED;
	if (category._id === lastCategoryId && category.sessions.find((s) => s.score != null)) return CategoryStatus.ONGOING;
	if (category._id === lastCategoryId) return CategoryStatus.OPEN;
	return CategoryStatus.LOCKED;
};

/**
 * From response formation progress API, transform to formation progress with category now have session instead of exercise as old one
 */
export const transformToFormationSessionProgress = (data) => {
	// Loop parcours list
	data.config.parcours_list.forEach((parcoursItem) => {
		const parcours = parcoursItem.parcours_id;
		// Loop categories
		parcours.categories.forEach((category) => {
			// Loop Sessions
			let totalPassedSessions = 0;

			category.sessions.forEach((session) => {
				// Loop session block
				const sessionScore = data.sessionScoresHasTrackResponses.find(
					(sessionScore) => sessionScore.session_id === session._id
				);
				session.status = decideSessionState(session, sessionScore, data.formation.last_session_id);
				if (session.status === SessionStatus.PASSED) {
					session.passedDate = sessionScore.passed_date;
					totalPassedSessions += 1;
				}
				session.score = sessionScore ? sessionScore.score : null; // Add field score to session object

				let hasSessionDone = true;

				session.sessionBlocks.forEach((sessionBlock) => {
					// Loop questionnaires
					if (sessionBlock.questionnaires == null) return;
					sessionBlock.questionnaires.forEach((questionnaire) => {
						let totalScore = 0;
						let trackCount = 0;
						let allTracksHaveScores = true;
						let coefficientTotal = 0;

						if (questionnaire.tracks == null) return;

						questionnaire.tracks.forEach((track) => {
							// Find track response and add to track
							if (sessionScore == null) track.trackResponse = null;
							else {
								const responseOfThisTrack = sessionScore.trackResponses.find(
									(t) =>
										t.track_id === track._id && t.session_id === session._id && t.session_block_id === sessionBlock._id
								);
								track.trackResponse = responseOfThisTrack ? responseOfThisTrack : null;

								if (track.trackResponse && track.trackResponse.score !== undefined) {
									const coefficient = track.coefficient || 1;

									totalScore += track.trackResponse.score * coefficient;
									trackCount++;
									coefficientTotal += coefficient;
								} else {
									allTracksHaveScores = false;
								}
							}
						});

						if (allTracksHaveScores && trackCount > 0) {
							// Calculate questionnaire average score
							questionnaire.questionnaireScore = totalScore / coefficientTotal;
						} else {
							// If any track lacks a score, set to null
							questionnaire.questionnaireScore = null;

							// If any questionnaireScore is null, excluding the TRAINING Block, the session is not considered done.
							if (sessionBlock.type !== 'training') {
								hasSessionDone = false;
							}
						}
					});
				});

				// Add hasSessionDone to session object
				session.hasSessionDone = hasSessionDone;
			});

			category.status = decideCategoryStatus(category, totalPassedSessions, data.formation.last_category);
		});
	});

	return {
		formation: data.formation,
		config: data.config,
		user: data.user,
		sessionScoresHasTrackResponses: data.sessionScoresHasTrackResponses,
	};
};
