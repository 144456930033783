import axios from 'axios';

const state = () => ({
	statuts: [],
});

const getters = {
	statuts: (state) => {
		return state.statuts;
	},
};
const mutations = {
	statuts(state, infos) {
		state.statuts = infos;
	},
};
const actions = {
	async getStatutsParcours(store, { idParcours }) {
		const res = await axios.get('/api/statuts/' + idParcours);
		store.commit('statuts', res.data.statuts);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
