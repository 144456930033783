<template>
	<div
		id="cookieModal"
		:class="{
			'slide-out-left': $vuetify.breakpoint.smAndUp,
			'slide-in': $vuetify.breakpoint.smAndUp && show,
			'slide-down': $vuetify.breakpoint.mdAndDown,
			'slide-up': $vuetify.breakpoint.mdAndDown && show,
		}"
	>
		<div id="cookieConsent" v-if="show">
			<p class="banner-description ortho-text-gray">
				Nous utilisons des cookies pour améliorer votre expérience utilisateur. Vous pouvez accepter ou continuer sans.
				Lire notre politique de protection des données personnelles
				<a href="https://www.orthographiq.com/donnees-personnelles?utm_source=cookieModal" target="_blank">ici</a> .
			</p>
			<div class="actions" :class="{ 'actions-mobile': $vuetify.breakpoint.mdAndDown }">
				<v-btn
					class="reject-btn"
					:class="{ 'mr-3': $vuetify.breakpoint.smAndUp, 'mb-3': $vuetify.breakpoint.mdAndDown }"
					@click="reject"
					>Rejeter</v-btn
				>
				<v-btn depressed class="black white--text" @click="consent"
					><v-icon size="19px" class="mr-1">mdi-cookie</v-icon> Accepter les cookies</v-btn
				>
			</div>
		</div>
	</div>
</template>

<script>
import Cookie from '@/utils/cookie.js';

export default {
	name: 'CookieConsent',
	data() {
		return { show: false };
	},
	created() {
		setTimeout(() => {
			if (Cookie.getValidCookie() == null) this.show = true;
		}, 4000);
	},
	methods: {
		consent() {
			Cookie.setCookie(true);
			this.show = false;
		},
		reject() {
			Cookie.setCookie(false);
			this.show = false;
		},
	},
};
</script>

<style lang="scss" scoped>
#cookieModal {
	z-index: 2;
	position: fixed;
	bottom: 14px;
	transition: all 1s;
	padding-left: 14px;
	padding-right: 14px;

	@media screen and (min-width: 480px) {
		bottom: 20px;
		padding-left: 24px;
		padding-right: 24px;
	}

	@media screen and (min-width: 960px) {
		max-width: 450px;
		bottom: 20px;
		left: 24px;
		padding: 0;
	}
}

#cookieConsent {
	padding: 1rem;
	font-family: 'Lato';
	background: white;
	font-size: 15px;
	border-radius: 8px;
	border: 1px solid #f1f1f1;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

	.banner-description {
		font-weight: 500;
	}

	a {
		text-decoration: underline;
		color: black;
	}

	.actions {
		* {
			font-family: 'Lato';
			font-size: 15px;
		}
		.reject-btn {
			background: #eee;
			color: #7c7c7c;
			box-shadow: none;
		}
	}

	.actions-mobile {
		display: flex;
		flex-direction: column;
	}
}

#cookieModal.slide-out-left {
	transform: translateX(-120%);
}
#cookieModal.slide-in {
	transform: translateX(0);
}

#cookieModal.slide-down {
	transform: translateY(120%);
}
#cookieModal.slide-up {
	transform: translateY(0);
}
</style>
