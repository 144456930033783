import axios from 'axios';

const state = () => ({
	token: null,
	user: {},
	formation: {},
});

const getters = {
	user: (state) => {
		return state.user;
	},
	formation: (state) => {
		return state.formation;
	},
	isAuthenticated: () => {
		return localStorage.getItem('token') != null ? true : false;
	},
};

const mutations = {
	getUser(state, user) {
		state.user = user;
	},
	getFormation(state, formation) {
		state.formation = formation;
	},
};
const actions = {
	async login({ state }, { email, password }) {
		const login = await axios.post('/api/auth/login', {
			email,
			password,
		});
		localStorage.setItem('token', login.data.token);
		state.token = login.data.token;
	},
	async register(store, { email, firstName, lastName }) {
		const res = await axios.post('/api/users/register', {
			email,
			firstName,
			lastName,
		});
		return res;
	},
	async resetPassword({ state }, { userId, password }) {
		const reset = await axios.post('/api/users/reset-password', {
			userId,
			password,
		});
		localStorage.setItem('token', reset.data.token);
		state.token = reset.data.token;
	},
	async logout() {
		localStorage.removeItem('token');
	},
	async getUser(store, { idUser }) {
		const userRes = await axios.get('/api/users/' + idUser);
		store.commit('getUser', userRes.data.user);
		store.commit('getFormation', userRes.data.formation);
	},
	async getInfoToOnboardingOrthograph(store, { idUser }) {
		const res = await axios.get('/api/users/prep-onboarding/' + idUser);
		store.commit('getUser', res.data.user);
		store.commit('getFormation', res.data.formation);
	},
	async setToken({ state }, { token }) {
		localStorage.setItem('token', token);
		state.token = token;
	},
	async getUserWihoutFormation(store, { idUser }) {
		const userRes = await axios.get('/api/users/' + idUser);
		store.commit('getUser', userRes.data.user);
		return userRes.data.user;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
