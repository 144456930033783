<template>
	<div v-if="renderBlank == false">
		<v-toolbar class="navbar" style="flex: none !important">
			<LogosColumn />
			<v-spacer></v-spacer>
			<div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex">
				<TopicDropdownDesktop v-if="showTopicDropdown()" />
				<RedactionNavItemsDesktop
					v-if="showRedactionNav"
					:currentRoute="currentRoute"
					:parcoursId="formationInfos.parcours_id"
				/>
				<OrthographNavItemsDesktop v-else :currentRoute="currentRoute" :formationId="formationInfos._id" />
			</div>

			<div v-if="$vuetify.breakpoint.mdAndUp">
				<div v-if="currentRoute.name === 'Login'">
					<router-link :to="{ name: 'Register' }" id="register" class="navBarLink">Inscription</router-link>
				</div>
				<div v-else-if="currentRoute.name == 'Register'">
					<router-link to="/login" id="login" class="navBarLink">Connexion</router-link>
				</div>
			</div>
			<div v-if="$vuetify.breakpoint.smAndDown">
				<RedactionNavItemsMobile
					v-if="showRedactionNav"
					:currentRoute="currentRoute"
					:formationId="formationInfos._id"
				/>
				<OrthographNavItemsMobile v-else :currentRoute="currentRoute" :formationId="formationInfos._id" />
			</div>

			<div v-if="$vuetify.breakpoint.smAndDown">
				<div v-if="currentRoute.name == 'Login'">
					<router-link :to="{ name: 'Register' }" id="register" class="navBarLink">Inscription</router-link>
				</div>
				<div v-else-if="currentRoute.name == 'Register'">
					<router-link to="/login" id="login" class="navBarLink">Connexion</router-link>
				</div>
			</div>
		</v-toolbar>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import LogosColumn from './LogosColumn';
import OrthographNavItemsDesktop from './OrthographNavItemsDesktop.vue';
import OrthographNavItemsMobile from './OrthographNavItemsMobile.vue';
import RedactionNavItemsDesktop from './RedactionNavItemsDesktop.vue';
import RedactionNavItemsMobile from './RedactionNavItemsMobile.vue';
import TopicDropdownDesktop from './TopicDropdownDesktop.vue';
import { showRedactionNavItems } from '@/utils/navbar.js';

export default {
	name: 'NavBar',
	components: {
		LogosColumn,
		OrthographNavItemsDesktop,
		OrthographNavItemsMobile,
		RedactionNavItemsDesktop,
		RedactionNavItemsMobile,
		TopicDropdownDesktop,
	},
	props: {
		formationInfos: Object,
		categoriesDone: Array,
		currentRoute: Object,
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		renderBlank() {
			return 'LoginSSO' === this.currentRoute.name;
		},
		showRedactionNav() {
			const formation = this.formationProgress ? this.formationProgress.formation : this.formationInfos;
			return showRedactionNavItems(this.currentRoute.name) || formation.for_session;
		},
	},
	methods: {
		showTopicDropdown() {
			const routeNames = [
				'Redaction Dashboard',
				'Redaction Dashboard Index',
				'Redaction Dashboard Parcours Detail',
				'Redaction Dashboard Chapter Detail',
				'Dashboard',
				'Profile',
				'Profile Index',
				'Parcours Detail',
				'Category Detail',
				'Leaderboard Weekly',
			];
			return routeNames.includes(this.currentRoute.name);
		},
	},
};
</script>

<style lang="scss" scoped>
.v-list-item {
	min-height: auto !important;
}
.navBarLink {
	font-weight: 500;
	letter-spacing: 0.0892857143em;
	text-transform: uppercase;
	font-size: 12px;
	color: #989fa9;
}

::v-deep {
	.navbar {
		height: fit-content !important;
		.v-toolbar__content {
			height: fit-content !important;
		}
		h2 {
			border-left: 1px solid #989fa9;
		}
	}
}

@media screen and (max-width: 600px) {
	.navbar {
		position: relative;
		z-index: 10;
	}
	a {
		&:first-child:not(#login) {
			border-right: none;
		}
	}
	.v-list {
		padding: 0px;
	}
	.v-list-item {
		position: absolute;
		background: white;
		right: 0px;
		padding: 10px;
		margin-right: 10px;
		border: 1px solid #989fa9;
	}
}
</style>
