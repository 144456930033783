import { ORTHOGRAPHIQ_APP_CONTEXT, ANDROID_REFERRER_KEYWORD, WEB_LINK_REFERRER_KEYWORD } from '../config/context';

export const getAppContextFromLocalStorage = () => {
	return JSON.parse(localStorage.getItem(ORTHOGRAPHIQ_APP_CONTEXT));
};

export const setAppContextOnLocalStorage = () => {
	const newReferrer = document.referrer; // empty string, android-app , http
	let contextLocal = getAppContextFromLocalStorage();

	if (!contextLocal || !contextLocal.referrer) {
		contextLocal = { referrer: newReferrer == '' ? window.location.href : newReferrer };
	} else if (
		contextLocal.referrer.includes(ANDROID_REFERRER_KEYWORD) &&
		newReferrer.includes(WEB_LINK_REFERRER_KEYWORD)
	) {
		// Do not update contextLocal
	} else if (contextLocal.referrer.includes(ANDROID_REFERRER_KEYWORD) && newReferrer == '') {
		contextLocal.referrer = window.location.href;
	} else if (
		contextLocal.referrer.includes(WEB_LINK_REFERRER_KEYWORD) &&
		newReferrer.includes(WEB_LINK_REFERRER_KEYWORD)
	) {
		// Do not update contextLocal
	} else if (
		contextLocal.referrer.includes(WEB_LINK_REFERRER_KEYWORD) &&
		newReferrer.includes(ANDROID_REFERRER_KEYWORD)
	) {
		contextLocal.referrer = newReferrer;
	}
	localStorage.setItem(ORTHOGRAPHIQ_APP_CONTEXT, JSON.stringify(contextLocal));
};
