<template>
	<v-alert color="#2dc7ae" :icon="false" class="trial-finished-alert mb-0 pt-2 pb-2 text-center" tile width="100%" dark>
		<div v-if="remainDaysTrialFinished > 2" class="hellobar-SE-div d-flex align-center justify-center">
			<span
				>Il te reste
				<span class="ortho-bold"
					>{{ remainDaysTrialFinished }}
					<span>jour<span v-if="remainDaysTrialFinished > 1">s</span></span>
				</span>
				pour débloquer un <span class="ortho-bold">coaching offert</span></span
			>
			<v-btn
				depressed
				small
				class="shades white black--text ml-5"
				href="https://calendly.com/orthographiq/faites-un-bilan-orthographique-avec-martin?month=2022-10&utm_source=application&utm_medium=cta-hellobar&utm_campaign=free-trial&utm_id=se-en-cours"
				target="_blank"
				@click="clickBtnRdvFinishSE()"
				>C'est parti !
			</v-btn>
		</div>

		<div v-else class="hellobar-SE-div d-flex align-center justify-center">
			<span class="ortho-bold">Débloquez toute la formation</span>
			<v-btn
				depressed
				small
				class="shades white black--text ml-5"
				href="https://www.orthographiq.com/offres/formation-orthographe?utm_medium=cta-hellobar&utm_campaign=free-trial&utm_id=se-terminee"
				target="_blank"
				@click="clickBtnRdvFinishSE()"
				>C'est parti !
			</v-btn>
		</div>
	</v-alert>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
	name: 'TrialFinished',
	computed: {
		...mapGetters('profile', { formationInfos: 'formationInfos' }),
		remainDaysTrialFinished() {
			return moment(this.formationInfos.end_date).diff(moment(), 'days');
		},
	},
	methods: {
		clickBtnRdvFinishSE() {
			if (process.env.VUE_APP_MIXPANEL == 'production') this.$mixpanel.track('CTA-hellobar-SE');
		},
	},
};
</script>

<style></style>
