<template>
	<v-col cols="6" md="6" class="d-flex align-center flex-row nav-container">
		<OrthographiqLogo />
		<span v-if="showEnterpriseLogo" class="separator">X</span>
		<img v-if="showEnterpriseLogo" class="enterprise-logo-nav pr-3" :src="formationProgress.user.entreprise_id.logo" />
	</v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import OrthographiqLogo from './OrthographiqLogo.vue';

export default {
	name: 'LogosColumn',
	components: { OrthographiqLogo },
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		showEnterpriseLogo() {
			return (
				this.formationProgress &&
				this.formationProgress.user.entreprise_id &&
				this.formationProgress.user.entreprise_id.logo != '' &&
				this.formationProgress.user.entreprise_id.logo != null
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.nav-container {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.separator {
	font-weight: 600;
	transform: translateX(-3px);
	padding-right: 8px;
}

@media screen and (max-width: 600px) {
	.nav-container {
		padding-left: 0px !important;
	}
	.header-name {
		display: none;
	}
}

@media screen and (max-width: 480px) {
	.separator {
		padding-right: 5px;
		transform: translateX(-5px);
		font-size: 1.1rem;
	}
}
</style>
