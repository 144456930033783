export const getStartOfMondayOfThisWeek = () => {
	const today = new Date();
	today.setHours(0, 0, 0, 0);

	const day = today.getDay(); // Get the day of the week (0 for Sunday, 6 for Saturday)
	const daysToSubtract = day === 0 ? 6 : day - 1; // Calculate how many days to subtract to get to Monday
	today.setDate(today.getDate() - daysToSubtract); // Subtract the days to get Monday

	return today;
};

export const getEndOfSundayThisWeek = () => {
	const today = new Date();
	today.setHours(0, 0, 0, 0);

	const day = today.getDay(); // Get the day of the week (0 for Sunday, 6 for Saturday)
	const daysToAdd = day === 0 ? 0 : 7 - day; // Calculate days to add to get to Sunday (considering wrapping around)

	today.setDate(today.getDate() + daysToAdd); // Add the days to get to Sunday
	today.setHours(23, 59, 59, 999); // Set to end of day

	return today;
};

export const checkDateWithinThisWeek = (somedate) => {
	const startOfMonday = getStartOfMondayOfThisWeek();
	const endOfSunday = getEndOfSundayThisWeek();

	const diffMonday = new Date(somedate) - startOfMonday;
	const diffSunday = endOfSunday - new Date(somedate);

	return diffMonday > 0 && diffSunday > 0;
};

export const getStartOfNextWednesday = (date) => {
	const givenDate = new Date(date);
	const dayOfWeek = givenDate.getDay();
	const daysUntilNextWednesday = (3 - dayOfWeek + 7) % 7 || 7;

	const nextWednesday = new Date(givenDate);
	nextWednesday.setDate(givenDate.getDate() + daysUntilNextWednesday);
	nextWednesday.setHours(0, 0, 0, 0);
	return nextWednesday;
};

export const getStartOfLastWednesday = (date) => {
	const givenDate = new Date(date);
	const day = givenDate.getDay();
	const startOfWednesday = new Date(givenDate);
	const diff = day >= 3 ? day - 3 : 7 + day - 3;

	startOfWednesday.setDate(givenDate.getDate() - diff);
	startOfWednesday.setHours(0, 0, 0, 0);

	return startOfWednesday;
};

/**
 * Check if now is past given time
 * @param {string} givenTimeString
 * @returns {Boolean}
 */
export const isPastGivenTime = (givenTimeString) => {
	const givenTime = new Date(givenTimeString);
	const now = new Date();
	return now > givenTime;
};

export const getMonthInFrench = (dateString) => {
	const monthNames = [
		'janvier',
		'février',
		'mars',
		'avril',
		'mai',
		'juin',
		'juillet',
		'août',
		'septembre',
		'octobre',
		'novembre',
		'décembre',
	];
	const date = new Date(dateString);

	return monthNames[date.getMonth()]; // getMonth() returns month from 0 to 11
};

export const isToday = (dateString) => {
	const dateObj = new Date(dateString);
	const today = new Date();
	return (
		dateObj.getDate() === today.getDate() &&
		dateObj.getMonth() === today.getMonth() &&
		dateObj.getFullYear() === today.getFullYear()
	);
};

export const getStartOfDay = (date) => {
	const d = new Date(date);
	return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

export const getEndOfDay = (date) => {
	let d = new Date(date);
	return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 999);
};
