import axios from 'axios';
import { groupExerciseToCategory, decideCategoryStatusAndArrangeOrder } from '../../core/formation-progress';
import { transformToFormationSessionProgress } from '../../core/formation-session-progress';
import router from '@/router/index.js';

const state = () => ({
	profileInfos: {},
	formationData: {},
	exercicesDoneData: [],
	categoriesDone: [],
	listCategories: [],
	rulesUser: [],
	strike: null,
	globalCurrent: 0,
	formationProgress: null,
});

const getters = {
	profileInfos: (state) => {
		return state.profileInfos;
	},
	formationInfos: (state) => {
		return state.formationData;
	},
	exercicesInfos: (state) => {
		return state.exercicesDoneData;
	},
	categoriesDone: (state) => {
		return state.categoriesDone;
	},
	listCategories: (state) => {
		return state.listCategories;
	},
	rulesUser: (state) => {
		return state.rulesUser;
	},
	strike: (state) => {
		return state.strike;
	},
	globalCurrent: (state) => {
		return state.globalCurrent;
	},
	formationProgress: (state) => {
		return state.formationProgress;
	},
	formation: (state) => {
		return state.formationProgress.formation;
	},
};

const mutations = {
	profileInfos(state, infos) {
		state.profileInfos = infos;
	},
	formationInfos(state, infos) {
		state.formationData = infos;
	},
	exercicesInfos(state, infos) {
		state.exercicesDoneData = infos;
	},
	categoriesDone(state, infos) {
		state.categoriesDone = infos;
	},
	listCategories(state, infos) {
		state.listCategories = infos;
	},
	rulesUser(state, infos) {
		state.rulesUser = infos;
	},
	strike(state, infos) {
		state.strike = infos;
	},
	globalCurrent(state, score) {
		state.globalCurrent = score;
	},
	formationProgress(state, progress) {
		state.formationProgress = progress;
	},
	updateTrackResponse(state, newTrackResponse) {
		// Extract the sessionScoresHasTrackResponses from the formationProgress state
		const sessionScores = state.formationProgress.sessionScoresHasTrackResponses;

		// Find the session score that matches with formation_id and session_id
		const sessionScore = sessionScores.find(
			(session) =>
				session.formation_id === newTrackResponse.formation_id && session.session_id === newTrackResponse.session_id
		);

		if (sessionScore) {
			const trackResponses = sessionScore.trackResponses;

			// Find the index of the track response that matches the given track response _id
			const trackIndex = trackResponses.findIndex((track) => track._id === newTrackResponse._id);

			// If a matching track response is found, replace it with the new track response
			if (trackIndex !== -1) {
				trackResponses.splice(trackIndex, 1, newTrackResponse);
			} else {
				// If no matching track response is found, add the new track response to the track responses array
				trackResponses.push(newTrackResponse);
			}
		} else {
			// If no matching session score is found, create a new session score with the new track response and add it to the sessionScoresHasTrackResponses array
			state.formationProgress.sessionScoresHasTrackResponses.push({
				formation_id: newTrackResponse.formation_id,
				session_id: newTrackResponse.session_id,
				trackResponses: [newTrackResponse],
			});
		}
	},
};

const actions = {
	// Noted: since redaction, getProfileAndExerciseFormationInfos is only used to get orthograph formation
	async getProfileAndExerciseFormationInfos(store) {
		if (localStorage.getItem('token') != null) {
			axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
		}
		const res = await axios.get('/api/profile');
		store.commit('profileInfos', res.data.profile);
		store.commit('formationInfos', res.data.formation);
		store.commit('exercicesInfos', res.data.exercicesDone);
		store.commit('categoriesDone', res.data.categoriesDone);
		store.commit('listCategories', res.data.listCategories);
	},
	async getUserProfile(store) {
		if (localStorage.getItem('token') != null) {
			axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
		}
		const res = await axios.get('/api/profile/user-profile');
		store.commit('profileInfos', res.data.profile);
		return res.data.profile;
	},
	async getRules(store, { idFormation }) {
		const rules = await axios.get('/api/profile/rules/' + idFormation);
		store.commit('rulesUser', rules.data.arrayOfRules);
		store.commit('globalCurrent', rules.data.globalCurrent);
	},
	async getStrike(store, { idFormation }) {
		const res = await axios.get('/api/profile/strike/' + idFormation);
		store.commit('strike', res.data.strike);
	},
	async selectNewCategory(store, { categoryId, formationId }) {
		const res = await axios.post('/api/profile/select-category', {
			categoryId,
			formationId,
		});
		return res;
	},
	async getProfileFormationInfos(store, { formationId }) {
		const token = localStorage.getItem('token');
		if (token === null) return;

		axios.defaults.headers.common['x-auth-token'] = token;
		const res = await axios.get('/api/profile/formationinfos/' + formationId);
		store.commit('formationInfos', res.data.formation);
		store.commit('categoriesDone', res.data.categoriesDone);
		return res.data;
	},
	async formationProgress(store, { idFormation, noUpdateStore }) {
		try {
			if (localStorage.getItem('token') != null)
				axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
			const res = await axios.get(`/api/formation/${idFormation}/progress`);
			if (res.data.formation.for_session === true) {
				// Process for formation using session
				const transformedProgress = transformToFormationSessionProgress(res.data);
				if (noUpdateStore !== true) store.commit('formationProgress', transformedProgress);
				return transformedProgress;
			}

			// Orthograph: Find and add exercise with user score to categories. Category --> exercise --> user score
			res.data.config.parcours_list.forEach((parcoursItem) => {
				groupExerciseToCategory(
					parcoursItem.parcours_id.categories,
					res.data.categoryWithExerciseAndUserScore,
					res.data.formation
				);
				parcoursItem.parcours_id.categories = decideCategoryStatusAndArrangeOrder(
					parcoursItem.parcours_id.categories,
					res.data.formation.last_category,
					res.data.categoriesDone
				);
			});

			if (noUpdateStore !== true)
				store.commit('formationProgress', {
					formation: res.data.formation,
					config: res.data.config,
					user: res.data.user,
				});
			return { formation: res.data.formation, config: res.data.config, user: res.data.user };
		} catch (error) {
			if (error.response.status === 403) {
				router.push({ name: 'Logout' });
			}
			return { error: true, message: error.message };
		}
	},
	async updateTrackResponse(store, newRes) {
		store.commit('updateTrackResponse', newRes);
	},
	async fetchCategoriesDoneByFormationId(store, { formationId }) {
		const res = await axios.get(`/api/category-done/by-formation/${formationId}`);
		store.commit('categoriesDone', res.data.categoriesDone);
		return res.data.categoriesDone;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
