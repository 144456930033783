import axios from 'axios';

const state = () => ({});

const getters = {};
const mutations = {};

const actions = {
	async getSessionDetail(store, { sessionId }) {
		const res = await axios.get('/api/sessions/' + sessionId + '?withDetails=true');
		return res.data.data.session;
	},
	async getSessionResponse(store, { sessionId, formationId }) {
		const res = await axios.get(`/api/sessions/reponses/${sessionId}/${formationId}`);
		return res.data.sessionScore;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
