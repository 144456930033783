import axios from 'axios';

const state = () => ({});

const getters = {};
const mutations = {};

const actions = {
	async getQuestionnaireDetail(store, { questionnaireId }) {
		const res = await axios.get('/api/questionnaires/' + questionnaireId);
		return res.data.data.questionnaire;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
