<template>
	<div class="loading-page-with-quote">
		<div>
			<div class="d-flex align-center flex-row nav-container">
				<OrthographiqLogo />
			</div>
		</div>
		<div class="loading-page-content">
			<transition name="fade" mode="out-in">
				<div key="image" class="loading-animation">
					<img :src="randomImage" alt="Loading Animation" />
				</div>
			</transition>
			<transition name="fade" mode="out-in">
				<div key="quote" class="loading-bar">
					<div class="d-flex mb-4 lo">
						<h3 class="text-uppercase loading-title">Chargement...</h3>
					</div>
					<div class="progress-bar-container mb-4">
						<div class="progress-bar" :style="{ width: progressWidth + '%' }"></div>
						<div class="progress-circle" :style="{ left: progressWidth + '%' }">
							<img src="@/assets/images/loading/logo-round.svg" alt="Circle Image" />
						</div>
					</div>
					<div class="d-flex">
						<p class="loading-text">{{ randomQuote }}</p>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import OrthographiqLogo from '../navbar/OrthographiqLogo.vue';
import { images, quotes } from './data';

export default {
	name: 'LoadingPageWithQuote',
	props: {
		autoTransition: {
			type: Boolean,
			default: true,
		},
		transitionInterval: {
			type: Number,
			default: 4000,
		},
		duration: {
			type: Number,
			default: 8000,
		},
	},
	components: {
		OrthographiqLogo,
	},
	data() {
		return {
			progressWidth: 0,
			randomImage: '',
			randomQuote: '',
			transitionIntervalId: null,
			progressIntervalId: null,
		};
	},
	created() {
		this.selectRandomQuote();
		this.selectRandomImage();
		this.startLoading();
		if (this.autoTransition) {
			this.startTransitionInterval();
		}
	},
	beforeDestroy() {
		if (this.transitionIntervalId) {
			clearInterval(this.transitionIntervalId);
		}
		if (this.progressIntervalId) {
			clearInterval(this.progressIntervalId);
		}
	},
	methods: {
		startLoading() {
			this.progressIntervalId = setInterval(() => {
				if (this.progressWidth < 100) {
					this.progressWidth += 100 / (this.duration / 100);
				} else {
					clearInterval(this.progressIntervalId);
					this.$emit('loading-complete');
				}
			}, 100);
		},
		selectRandomQuote() {
			const randomIndex = Math.floor(Math.random() * quotes.length);
			this.randomQuote = quotes[randomIndex];
		},
		selectRandomImage() {
			const randomIndex = Math.floor(Math.random() * images.length);
			this.randomImage = images[randomIndex];
		},
		startTransitionInterval() {
			this.transitionIntervalId = setInterval(() => {
				this.selectRandomQuote();
			}, this.transitionInterval);
		},
	},
};
</script>

<style lang="scss" scoped>
.loading-page-with-quote {
	position: fixed;
	padding: 4px 28px;
	width: 100%;
	left: 0;
	z-index: 11;
	top: 0;
	display: flex;
	flex-direction: column;
	height: 100vh;
	background-color: #f9f9f9;

	.loading-page-content {
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: center;
		margin-top: 24px;

		.loading-animation {
			display: flex;
			justify-content: center;
			height: 500px;
			img {
				height: 100%;
			}
		}

		.loading-bar {
			width: 80%;
			max-width: 700px;
			text-align: center;
		}

		.progress-bar-container {
			position: relative;
			width: 100%;
			height: 20px;
			background-color: #ffffff;
			border: 1px solid #a9a9a9;
			border-radius: 10px;
		}

		.progress-bar {
			height: 100%;
			background-color: #333;
			width: 0;
			border-radius: 10px;
			transition: width 0.2s ease;
		}

		.progress-circle {
			position: absolute;
			top: -11px;
			transform: translateX(-50%);
			transition: left 0.2s ease;
		}

		.progress-circle img {
			width: 40px;
			height: 40px;
		}

		.loading-title {
			font-size: 21px;
			letter-spacing: 0.5px;
		}

		.loading-text {
			font-size: 18px;
			text-align: left;
		}
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
@media screen and (max-width: 600px) {
	.loading-page-with-quote {
		padding: 4px 16px;
		.loading-page-content {
			margin-top: 40px;
			.loading-bar {
				width: 90%;
			}
			.loading-animation {
				height: 250px;
			}

			.progress-bar-container {
				height: 16px;
			}

			.progress-circle img {
				width: 35px;
				height: 35px;
			}

			.loading-title {
				margin-top: 10px;
				font-size: 18px;
			}
			.loading-text {
				font-size: 16px;
			}
		}
	}
}

::v-deep {
	@media screen and (max-width: 600px) {
		.ortho-logo-nav {
			height: 56px;
		}
	}
}
</style>
