<template>
	<v-toolbar-items v-if="show" cols="6" md="6" id="menu-nav">
		<div id="menu-nav-desktop" v-if="currentRoute.name != 'Login'">
			<router-link
				:to="{ name: 'Redaction Dashboard Index', params: { formationId: formationId } }"
				class="pl-3 pr-3 navBarLink"
			>
				<span>Espace Personnel</span>
			</router-link>
			<router-link
				:to="{ name: 'Assistant', params: { formationId: formationId } }"
				class="pl-3 pr-8 navBarLink"
				:class="{ purpleColor: currentRoute.name == 'Assistant' }"
			>
				<span @click="onDashboardAssistant()">Assistant</span>
			</router-link>
		</div>
	</v-toolbar-items>
</template>

<script>
import { mapGetters } from 'vuex';
import { showNavBar } from '@/utils/navbar.js';
import MixPanel from '@/utils/mixpanel.js';

export default {
	name: 'RedactionNavItemsDesktop',
	props: {
		currentRoute: Object,
		parcoursId: String,
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		show() {
			return showNavBar(this.currentRoute.name);
		},
		formationId() {
			if (this.formationProgress == null) return '';
			return this.formationProgress.formation._id;
		},
	},
	methods: {
		onDashboardAssistant() {
			MixPanel.trackToAssistant;
		},
	},
};
</script>

<style scoped lang="scss">
#menu-nav-desktop {
	display: flex;
	align-items: center;
	a {
		&:not(:last-child) {
			border-right: 1px solid #989fa9;
		}
	}

	a {
		color: #989fa9 !important;
	}

	a:hover {
		color: #686bdf !important;
	}

	.navBarLink {
		font-weight: 500;
		letter-spacing: 0.0892857143em;
		text-transform: uppercase;
		font-size: 12px;
		height: 15px;
	}

	.purpleColor {
		color: #686bdf !important;
	}
}
</style>
