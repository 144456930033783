import axios from 'axios';

const state = () => ({
	textsSaved: [],
});

const getters = {
	textsSaved: (state) => {
		return state.textsSaved;
	},
};
const mutations = {
	textsSaved(state, infos) {
		state.textsSaved = infos;
	},
};
const actions = {
	async postRating(store, { formationId, rating, text }) {
		const res = await axios.post('/api/assistant/rating', {
			formationId,
			rating,
			text,
		});
		return res;
	},
	async getTextsSaved(store, { formationId }) {
		const res = await axios.get('/api/assistant/text/' + formationId);
		store.commit('textsSaved', res.data.textsSaved);
	},
	async deleteTextsSaved(store, { textId }) {
		const res = await axios.post('/api/assistant/text/delete', {
			textId,
		});
		return res;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
