import axios from 'axios';

const state = () => ({
	familyTags: null,
});

const getters = {
	familyTags: (state) => {
		return state.familyTags;
	},
};
const mutations = {
	familyTags(state, infos) {
		state.familyTags = infos;
	},
};
const actions = {
	async getFamilyTags(store) {
		const res = await axios.get('/api/rules/family');
		store.commit('familyTags', res.data.allFamilyTags);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
