import axios from 'axios';

const state = () => ({});

const getters = {};
const mutations = {};

const actions = {
	async getAllParcoursByUserId(store, { userId }) {
		const res = await axios.get('/api/parcours/findByUserId/' + userId);
		return res.data.data;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
