import axios from 'axios';

const state = () => ({
	gender: '',
	natif: '',
	birthDate: '',
	objectives: [],
	firstName: '',
	lastName: '',
	phone: 0,
});

const getters = {};

const mutations = {
	usersInfosSociales(state, { gender, natif, birthDate }) {
		state.gender = gender;
		state.natif = natif;
		state.birthDate = birthDate;
	},
	lastInfos(state, { firstName, lastName, phone }) {
		state.phone = phone;
		state.firstName = firstName;
		state.lastName = lastName;
	},
	objectivesSelected(state, objectives) {
		let objectivesName = [];
		objectives.map((element) => {
			delete element.checked;
			delete element.description;
			objectivesName.push(element.title);
		});
		state.objectives = objectivesName;
	},
};
const actions = {
	registerSocialQs(store, { gender, natif, birthDate }) {
		store.commit('usersInfosSociales', { gender, natif, birthDate });
	},
	registerObjectives(store, { objectivesSelected }) {
		store.commit('objectivesSelected', objectivesSelected);
	},
	lastInfos(store, { firstName, lastName, phone }) {
		store.commit('lastInfos', { firstName, lastName, phone });
	},
	async registerInfosOnboarding({ state }, { idUser }) {
		const res = await axios.post('/api/users/onboarding', {
			idUser,
			state,
		});
		return res;
	},
	async registerPeriodTrial(store, { idUser, trialSelected }) {
		const res = await axios.post('/api/users/register/trial', {
			idUser,
			trialSelected,
		});
		return res;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
