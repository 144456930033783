export const showNavBar = (routeName) => {
	const excludedRoutes = [
		null,
		'Onboarding',
		'TestPositionnement',
		'Login',
		'Register',
		'LoginSSO',
		'LoginSSOProcess',
		'ForgetPassword',
	];
	return !excludedRoutes.includes(routeName);
};

export const showRedactionNavItems = (routeName) => {
	const redactionPathNames = [
		'Redaction Dashboard',
		'Redaction Dashboard Index',
		'Redaction Dashboard Parcours Detail',
		'Redaction Dashboard Chapter Detail',
		'SessionDetailPage',
		'TrackPage',
		'SessionResultPage',
		'Report Redaction',
		'Redaction Chapter Report',
		'Redaction Parcours Report',
		'Redaction Formation Report',
	];
	return redactionPathNames.includes(routeName);
};
