const state = () => ({
	selectingFormationId: null,
	isLoading: false,
});

const getters = {
	selectingFormationId: (state) => {
		return state.selectingFormationId;
	},
	isSwitchFormationStateLoading: (state) => {
		return state.isLoading;
	},
};
const mutations = {
	updateFormationId(state, formationId) {
		state.selectingFormationId = formationId;
	},
	setLoading(state, payload) {
		state.isLoading = payload;
	},
};
const actions = {
	async selectFormationId(store, { formationId }) {
		store.commit('updateFormationId', formationId);
	},
	triggerLoading({ commit }, duration = 8000) {
		commit('setLoading', true);
		setTimeout(() => {
			commit('setLoading', false);
		}, duration);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
