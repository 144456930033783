/**
 * Desciption: Store for resources database nested and grouped: topic, category, exercise, story, rule.
 * These value are not changed during user lifecycle so this store will help saving query.
 */
import axios from 'axios';

const state = () => ({
	topics: [],
});

const getters = {
	topics: (state) => state.topics,
};

const mutations = {
	updateTopic(state, topic) {
		const existedTopic = state.topics.find((topic) => topic._id === topic._id);
		if (existedTopic != null) return;
		state.topics.push(topic);
	},
	updateRuleTagsToParcours(state, { parcoursId, ruleTags }) {
		state.topics.forEach((topic) => {
			const parcoursIndex = topic.parcours.findIndex((parcours) => parcours._id === parcoursId);
			if (parcoursIndex === -1) return 'Not found';
			topic.parcours[parcoursIndex].ruleTags = ruleTags;
		});
	},
	clearAndUpdateTopics(state, topics) {
		state.topics = topics;
	},
};

const actions = {
	async getTopicAndParcours(store, { topicId }) {
		const res = await axios.get(`/api/topics/${topicId}?parcours=true`);
		store.commit('updateTopic', res.data.topic);
	},
	async getSummaryRuleTagsByParcours(store, { parcoursId }) {
		const res = await axios.get(`/api/rules/summary/${parcoursId}`);
		store.commit('updateRuleTagsToParcours', { parcoursId, ruleTags: res.data });
		return res.data;
	},
	async getAllTopicsAndParcours(store) {
		const res = await axios.get(`/api/topics?parcours=true`);
		store.commit('clearAndUpdateTopics', res.data.data.topics);
		return res.data.data.topics;
	},
	async getResource(store, { resourceId }) {
		const res = await axios.get(`/api/resources/${resourceId}`);
		return res.data.resource;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
