import axios from 'axios';

const state = () => ({
	stories: [],
});

const getters = {};

const mutations = {};

const actions = {
	async getStoriesByIds(store, { storyIds }) {
		const res = await axios.post('/api/stories/find', { by: '_id', values: storyIds });
		return res.data.data;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
