<template>
	<v-toolbar-items v-if="show" cols="6" md="6" id="menu-nav-mobile">
		<div class="burger-container">
			<img src="@/assets/icons/open-menu.svg" alt="menu" style="width: 25px" @click="openMenu = !openMenu" />
			<v-list v-if="openMenu == true && currentRoute.name != 'Login'">
				<div class="d-flex flex-column">
					<div class="nav-menu rounded shadow-blue-not-openned">
						<TopicDropdownMobile v-if="$route.path.includes('dashboard/profile')" :closeMenu="closeMenu" />
						<router-link
							:to="{ name: 'Redaction Dashboard Index', params: { formationId: formationId } }"
							:class="{ purpleColor: currentRoute.name == 'Profile' }"
						>
							<span @click="closeMenu">
								<span @click="onDashboardProfile()" class="navBarLink">Espace Personnel</span>
							</span>
						</router-link>
						<router-link
							:to="{ name: 'Assistant', params: { formationId: formationId } }"
							:class="{ purpleColor: currentRoute.name == 'Assistant' }"
						>
							<span @click="closeMenu"><span @click="onDashboardAssistant()" class="navBarLink">Assistant</span></span>
						</router-link>
					</div>
				</div>
			</v-list>
		</div>
	</v-toolbar-items>
</template>

<script>
import { mapGetters } from 'vuex';
import { showNavBar } from '@/utils/navbar.js';
import MixPanel from '@/utils/mixpanel.js';
import TopicDropdownMobile from './TopicDropdownMobile';

export default {
	name: 'RedactionNavItemsMobile',
	components: { TopicDropdownMobile },
	props: { currentRoute: Object },
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		show() {
			return showNavBar(this.currentRoute.name);
		},
		formationId() {
			if (this.formationProgress == null) return '';
			return this.formationProgress.formation._id;
		},
	},
	data: () => {
		return { openMenu: false };
	},
	methods: {
		onDashboardRules() {
			MixPanel.trackToRule;
		},
		onDashboardProfile() {
			MixPanel.trackToProfile;
		},
		onDashboardAssistant() {
			MixPanel.trackToAssistant;
		},
		closeMenu() {
			this.openMenu = false;
		},
	},
};
</script>

<style scoped lang="scss">
#menu-nav-mobile {
	.navBarLink {
		text-transform: capitalize;
		font-size: 15px;
		cursor: pointer;
		font-family: 'Roboto', sans-serif;
		color: #989fa9;
		&:hover {
			color: #686bdf !important;
		}
	}

	.purpleColor {
		color: #686bdf !important;
	}

	.burger-container {
		display: flex;
		.nav-menu {
			min-width: 180px;
			display: flex;
			flex-direction: column;
			gap: 8px;
			background-color: white;
			padding: 16px;
			position: absolute;
			right: 16px;
			top: 60px;
		}
	}
}
</style>
