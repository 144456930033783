export const images = [
	require('@/assets/images/loading/loading-quote-img-1.png'),
	require('@/assets/images/loading/loading-quote-img-2.png'),
	require('@/assets/images/loading/loading-quote-img-3.png'),
	require('@/assets/images/loading/loading-quote-img-4.png'),
	require('@/assets/images/loading/loading-quote-img-5.png'),
	require('@/assets/images/loading/loading-quote-img-6.png'),
	require('@/assets/images/loading/loading-quote-img-7.png'),
	require('@/assets/images/loading/loading-quote-img-8.png'),
];

export const quotes = [
	`L’Académie française, fondée en 1635, veille à la pureté de la langue française.`,
	`La Chanson de Roland est le plus ancien chef-d'œuvre littéraire français.`,
	`Le mot "bistrot" vient de l'invasion russe de Paris en 1814.`,
	`Le français était la langue de la diplomatie jusqu'au 20e siècle.`,
	`Les troubadours ont contribué à l'évolution de la poésie française médiévale.`,
	`La langue française compte environ 220 000 mots.`,
	`La devise "Liberté, Égalité, Fraternité" est née pendant la Révolution française.`,
	`La langue française a plus de 1 200 homophones.`,
	`Les mots français "à la carte" sont utilisés dans la gastronomie mondiale.`,
	`Le français est la langue officielle de 29 pays.`,
	`Le mot le plus long en français est "anticonstitutionnellement".`,
	`La lettre "ç" est appelée cédille et change le son du "c".`,
	`En français, les jours de la semaine ne prennent pas de majuscule.`,
	`"Le Petit Prince" est le livre le plus traduit après la Bible.`,
	`"Clé" et "clef" sont deux orthographes correctes pour le même mot.`,
	`"Robot" est emprunté au tchèque, signifiant "travailleur esclave".`,
	`"Café" vient de l'arabe "qahwa".`,
	`"Baguette" signifie petite tige ou bâton en français.`,
	`Le français dérive du latin vulgaire parlé par les Romains.`,
	`Le français est la langue de l'amour.`,
	`"Pamplemousse" vient du néerlandais.`,
	`La lettre "E" est la plus utilisée en français.`,
	`"Oiseau" est le plus petit mot avec toutes les voyelles.`,
	`Le plus ancien texte en français date de l'an 842.`,
	`Molière a enrichi le français de plus de 100 nouveaux mots.`,
	`La Marseillaise, hymne national français, fut composée en 1792.`,
	`Le français fut la langue officielle de l'Angleterre pendant 300 ans.`,
	`Le français a 17 temps verbaux, dont certains peu utilisés.`,
	`La littérature française compte 15 prix Nobel à ce jour.`,
	`L'accent grave en français peut changer le sens d'un mot.`,
];
