import axios from 'axios';

const state = () => ({
	moyenneScoreInPercent: 0,
	globalScoreRules: 0,
	globalTargetScoreRules: 0,
	scoresRules: [],
});

const getters = {
	moyenneScoreInPercent: (state) => {
		return state.moyenneScoreInPercent;
	},
	globalScoreRules: (state) => {
		return state.globalScoreRules;
	},
	globalTargetScoreRules: (state) => {
		return state.globalTargetScoreRules;
	},
	scoresRules: (state) => {
		return state.scoresRules;
	},
};
const mutations = {
	moyenneScoreInPercent(state, score) {
		state.moyenneScoreInPercent = Math.floor(score);
	},
	globalScoreRules(state, score) {
		state.globalScoreRules = score;
	},
	globalTargetScoreRules(state, score) {
		state.globalTargetScoreRules = score;
	},
	scoresRules(state, scoreRules) {
		state.scoresRules = scoreRules;
	},
};
const actions = {
	async getCategoryReport(store, { idFormation, idCategory }) {
		const res = await axios.get('/api/category/report/' + idFormation + '/' + idCategory);
		const { moyenneScoreInPercent, globalScoreRules, globalTargetScoreRules, scoresRules } = res.data;
		store.commit('moyenneScoreInPercent', moyenneScoreInPercent);
		store.commit('globalScoreRules', globalScoreRules);
		store.commit('globalTargetScoreRules', globalTargetScoreRules);
		store.commit('scoresRules', scoresRules);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
