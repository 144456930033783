const trackToProfile = () => {
	if (process.env.VUE_APP_MIXPANEL == 'production') {
		this.$mixpanel.track('menu-goToProfile');
	}
};

const trackToRule = () => {
	if (process.env.VUE_APP_MIXPANEL == 'production') {
		this.$mixpanel.track('menu-goToRules');
	}
};

const trackToAssistant = () => {
	if (process.env.VUE_APP_MIXPANEL == 'production') {
		this.$mixpanel.track('menu-goToAssistant');
	}
};

export default { trackToProfile, trackToRule, trackToAssistant };
