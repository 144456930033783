import axios from 'axios';

const state = () => ({
	weeklyLeaderboard: {
		data: null,
		updatedAt: null,
	},
});

const getters = {
	weeklyLeaderboard: (state) => state.weeklyLeaderboard,
};

const mutations = {
	updateWeeklyLeaderboard(state, payload) {
		state.weeklyLeaderboard.data = payload;
		state.weeklyLeaderboard.updatedAt = Date();
	},
};

const actions = {
	async fetchWeeklyLeaderboard(store) {
		if (localStorage.getItem('token') != null)
			axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
		const res = await axios.get('/api/leaderboard/weekly');
		const sortedPositionASC = res.data.data.weeklyLeaderboard.sort((a, b) => a.position - b.position);
		store.commit('updateWeeklyLeaderboard', sortedPositionASC);
		return sortedPositionASC;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
