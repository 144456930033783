import axios from 'axios';

const state = () => ({});

const getters = {};
const mutations = {};

const actions = {
	async sendFeedback(store, feedbackSession) {
		const res = await axios.post('/api/feedbackSession/create', feedbackSession);
		return res.data.feedbackSession;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
