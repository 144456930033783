import { CategoryStatus } from '../constants/category';
import { ExerciseStatus } from '../constants/exercise';

/**
 * Decide exercise status  depend on user score
 * @param {object} exerciseIncludeUserScore
 * @param {string} lastExerciseId is exercise user doing and need to finish. This exercise status will be: OPEN | ONGOING
 * @returns {ExerciseStatus}
 */
const decideExerciseStatus = (exerciseIncludeUserScore, lastExerciseId) => {
	const hasPassedScore = exerciseIncludeUserScore.usersScores.findIndex((userScore) => userScore.score >= 0.8);

	if (hasPassedScore != -1) return ExerciseStatus.PASSED;
	if (lastExerciseId === exerciseIncludeUserScore._id) {
		return exerciseIncludeUserScore.usersScores.length > 0 ? ExerciseStatus.ONGOING : ExerciseStatus.OPEN;
	}
	return ExerciseStatus.LOCKED;
};

/**
 * Decide category status and arranged by order  by order: ongoing -> completed -> locked
 * @param {*} categoriesWitherExerciseAndScores
 * @param {string} lastCategoryId is category ID user is doing. Status of this category is OPEN | ONGOING
 * @returns {Category[]} arranged category array
 */
export const decideCategoryStatusAndArrangeOrder = (
	categoriesWitherExerciseAndScores,
	lastCategoryId,
	categoriesDone
) => {
	const passedCategories = [];
	const ongoingOrOpenCategories = []; // Should ONLY have 1 ONGOING or OPEN category at a time.
	const lockedCategories = [];
	categoriesWitherExerciseAndScores.forEach((category) => {
		const totalPassedExercise = category.exercises.filter((exercise) => exercise.status === ExerciseStatus.PASSED)
			.length;
		if (lastCategoryId === category._id) {
			const hasAnyUserScore = category.exercises.find((exercise) => exercise.usersScores.length > 0);
			category.status = hasAnyUserScore ? CategoryStatus.ONGOING : CategoryStatus.OPEN;
			ongoingOrOpenCategories.push(category);
		} else if (totalPassedExercise === category.exercises.length) {
			category.status = CategoryStatus.COMPLETED;
			category.doneDate = categoriesDone.find((catDone) => catDone.category_id === category._id).done_date;
			passedCategories.push(category);
		} else {
			category.status = CategoryStatus.LOCKED;
			lockedCategories.push(category);
		}
	});

	passedCategories.sort((a, b) => new Date(b.doneDate) - new Date(a.doneDate)); // Sort passedCategories by doneDate DESC

	return [...ongoingOrOpenCategories, ...passedCategories, ...lockedCategories];
};

/**
 * Find Exercise in CategoryWithExerciseAndUserScore array and add into parent category
 * Warning: SIDE EFFECT
 * @param {Category[]} categories
 * @param {CategoryWithExerciseAndUserScore[]} categoryWithExerciseAndUserScore
 */
export const groupExerciseToCategory = (categories, categoryWithExerciseAndUserScore, formation) => {
	categories.forEach((category) => {
		const categoryHasExerciseAndScore = categoryWithExerciseAndUserScore.find((item) => item._id === category._id);
		if (categoryHasExerciseAndScore == null) return;
		categoryHasExerciseAndScore.exercices.forEach(
			(exercise) => (exercise.status = decideExerciseStatus(exercise, formation.last_exercice))
		);
		category.exercises = categoryHasExerciseAndScore.exercices;
	});
};
