<template>
	<div class="resource-video-type">
		<div v-if="isYoutube">
			<iframe
				class="rounded"
				:src="youtubeEmbedUrl"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				referrerpolicy="strict-origin-when-cross-origin"
				allowfullscreen
			></iframe>
		</div>
		<video v-else controls>
			<source :src="resourceItem.url" :type="resourceType" />
			Your browser does not support the video tag.
		</video>
	</div>
</template>

<script>
export default {
	name: 'VideoResource',
	props: {
		resourceItem: Object,
	},
	computed: {
		isYoutube() {
			return this.resourceItem.url.includes('youtube.com') || this.resourceItem.url.includes('youtu.be');
		},
		youtubeEmbedUrl() {
			if (this.resourceItem.url.includes('youtube.com')) {
				const videoId = new URL(this.resourceItem.url).searchParams.get('v');
				return `https://www.youtube.com/embed/${videoId}`;
			} else if (this.resourceItem.url.includes('youtu.be')) {
				const videoId = this.resourceItem.url.split('/').pop();
				return `https://www.youtube.com/embed/${videoId}`;
			}
			return this.resourceItem.url;
		},
		resourceType() {
			const url = this.resourceItem.url;
			if (url.endsWith('.mp4')) return 'video/mp4';
			if (url.endsWith('.webm')) return 'video/webm';
			if (url.endsWith('.ogg')) return 'video/ogg';
			if (url.endsWith('.mkv')) return 'video/x-matroska';
			if (url.endsWith('.mov')) return 'video/x-quicktime';
			if (url.endsWith('.avi')) return 'video/x-msvideo';

			return 'video/mp4';
		},
	},
};
</script>

<style lang="scss" scoped>
.resource-video-type {
	width: 100%;
	iframe,
	video {
		width: 100%;
		aspect-ratio: 16 / 9;
		border-radius: 8px;
	}
	.rounded {
		border-radius: 8px;
	}
}
</style>
