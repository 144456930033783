const rules = {
	personel: 'PERSONNEL',
};

/**
 * Get stored cookie
 * @param {string} rule regulation rule
 * @returns
 */
const getValidCookie = (rule = rules.personel) => {
	const cookie = localStorage.getItem(`${process.env.VUE_APP_LINKTOPLATFORM}-${rule}`);
	if (cookie == null) return null;

	const cookieObj = JSON.parse(cookie);
	const unixExpireTime = new Date(cookieObj.expireAt).getTime();

	if (Date.now() > unixExpireTime) return null;
	return cookieObj;
};

/**
 * Set cookie
 * @param {boolean} consent
 * @param {String} rule default PERSONNEL
 * @param {Number} validDays default 90 days
 * @returns
 */
const setCookie = (consent, rule = rules.personel, validDays = 90) => {
	const cookie = {
		name: `${process.env.VUE_APP_LINKTOPLATFORM}-${rule}`,
		rule: rule,
		device: navigator.userAgent,
		expireAt: new Date(Date.now() + 24 * 60 * 60 * 1000 * validDays),
		policy: 'https://www.orthographiq.com/donnees-personnelles',
	};

	if (consent) {
		cookie.consent = true;
		cookie.consentedAt = new Date();
	} else {
		cookie.consent = false;
		cookie.rejectedAt = new Date();
	}

	localStorage.setItem(`${process.env.VUE_APP_LINKTOPLATFORM}-${rule}`, JSON.stringify(cookie));
};

export default { getValidCookie, setCookie };
