<template>
	<div v-if="formationProgress" class="topic-dropdown d-flex align-center justify-center">
		<v-select
			:items="topics"
			v-model="selectedTopic"
			item-text="name"
			item-value="id"
			outlined
			dense
			hide-details
			hide-selected
			:menu-props="{ top: false, offsetY: true }"
			:color="'#7854F7'"
			append-icon="mdi-chevron-down"
			ref="topicDropdown"
			@change="closeDropdown"
		>
			<template v-slot:item="data">
				<v-list-item v-if="checkTopicReady(data.item)" @click="selectActiveItem(data.item)">
					<div class="topic-dropdown-item">{{ data.item.name }}</div>
				</v-list-item>
				<v-list-item v-else @click="selectLockedItem(data.item)">
					<div class="topic-dropdown-item d-flex justify-space-between w-100">
						<span>{{ data.item.name }}</span>
						<v-icon size="20px">mdi-lock-outline</v-icon>
						<TopicIntroductionModal v-if="showModal" :topic="modalTopic" :closeModalInParent="closeModal" />
					</div>
				</v-list-item>
			</template>
			<template v-slot:selection="{ item, index }">
				<div class="custom-selection" :key="index">{{ item.name }}</div>
			</template>
		</v-select>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import TopicIntroductionModal from '@/components/TopicIntroductionModal/TopicIntroductionModal.vue';
import { isPastGivenTime } from '@/utils/time.js';

export default {
	name: 'TopicDropdownDesktop',
	components: { TopicIntroductionModal },
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	data: () => ({
		topics: [],
		selectedTopic: null,
		modalTopic: null,
		showModal: false,
	}),
	methods: {
		checkTopicReady(transformedTopic) {
			return transformedTopic.formationId && isPastGivenTime(transformedTopic.beginDate);
		},
		closeDropdown() {
			this.$refs.topicDropdown.blur();
		},
		closeModal() {
			this.modalTopic = null;
			this.showModal = false;
		},
		directToRedaction(formationId) {
			this.$router.push({
				name: 'Redaction Dashboard Index',
				params: { formationId: formationId },
				// query: { directTo: 'parcours-detail' }, Comment out this query to not redirect to categories list.
			});
		},
		directToOrthograph() {
			this.$router.push({ name: 'Dashboard' });
		},
		async directToFormation(selectedTopic) {
			await this.$store.dispatch('profile/formationProgress', { idFormation: selectedTopic.formationId });

			if (selectedTopic.forSession === true) return this.directToRedaction(selectedTopic.formationId);
			this.directToOrthograph(this.formationProgress);
		},
		selectLockedItem(topic) {
			this.modalTopic = topic;
			this.showModal = true;
			this.closeDropdown();

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('Topic change locked');
			}
		},
		selectActiveItem(topic) {
			this.selectedTopic = topic;
			this.$store.dispatch('appState/triggerLoading');
			this.$store.dispatch('appState/selectFormationId', { formationId: topic.formationId });
			this.closeDropdown();
			this.directToFormation(topic);

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('Topic change');
			}
		},
		async initGetAllTopics(userId) {
			if (userId == null) return;
			const transformedTopic = await this.$store.dispatch('topics/getAllTopics', { userId });
			this.topics = transformedTopic; // Transformed topic: if user has this topic, it will have formationId and formationConfigId.

			const topicOfThisFormation = transformedTopic.find(
				(topic) => topic.formationId === this.formationProgress.formation._id
			);
			this.$store.dispatch('appState/selectFormationId', { formationId: topicOfThisFormation.formationId });
			this.selectedTopic = topicOfThisFormation;
		},
	},
	async mounted() {
		if (this.formationProgress == null) return;
		await this.initGetAllTopics(this.formationProgress.user._id);
	},
	watch: {
		formationProgress() {
			if (this.formationProgress == null) return;
			this.initGetAllTopics(this.formationProgress.user._id);
		},
	},
};
</script>

<style lang="scss" scoped>
.topic-dropdown {
	width: 170px;

	.custom-selection {
		display: flex;
		justify-content: center;
		width: 100%;
		color: #7854f7;
		text-transform: uppercase;
		font-size: 15px;
		font-weight: bold;
	}
}

.topic-dropdown-item {
	font-weight: bold;
	text-transform: uppercase;
}
</style>
