import axios from 'axios';

const state = () => ({});

const getters = {};
const mutations = {};

const actions = {
	async fetchSessionScores(store, { formationId }) {
		const res = await axios.get(`/api/session-scores?filter={"formationId": "${formationId}"}`);
		return res.data.sessionScores;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
